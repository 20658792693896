import React, { Component } from 'react'
import Helmet from 'react-helmet'

import Chrome from '../components/Chrome'
import Header from '../components/Header'
import FirebaseContext from '../components/FirebaseContext'

import getFirebase from '../firebase'
import SignIn from '../containers/SignIn'


const validAccount = (user) => {

  if(!user) {
    return false;
  }

  return user.email.split('@')[1] === 's.evolvetechnology.se' || user.email.split('@')[1] === 'evolvetechnology.se';
}

export default class TemplateWrapper extends Component {
  componentDidMount() {
    const app = import('firebase/app')
    const auth = import('firebase/auth')

    Promise.all([app, auth]).then(values => {
      const firebase = getFirebase(values[0])
      this.setState({ firebase })

      firebase.auth().onAuthStateChanged(user => {

        if (!validAccount(user)) {
          this.setState({ authenticated: false })
        } else {
          this.setState({ authenticated: true, user: user })
        }
      })
    })

  }

  logOut = () => {
    const { firebase } = this.state
    firebase.auth().signOut().catch(function(error) {
      console.error('Failed to log out.');
    });
  }

  render = () => {

    if(!this.state) {
      return null;
    }

    const { firebase, authenticated } = this.state

    if(!authenticated) {
      return (
        <FirebaseContext.Provider value={firebase}>
          <SignIn  />
        </FirebaseContext.Provider>
      )
    }

    if(authenticated) {

      return(
        <FirebaseContext.Provider value={firebase}>
          <Chrome header={<Header />} logOutCallback={this.logOut} user={this.state.user}>
            <Helmet title="Evolve HQ" />
            {this.props.children}
          </Chrome>
        </FirebaseContext.Provider>
      )
    }
  }
}
