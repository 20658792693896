import React from 'react'
import { StyledFirebaseAuth } from 'react-firebaseui'

import { getUiConfig } from '../../firebase'
import { withFirebase } from '../../components/FirebaseContext'

const SignIn = ({ firebase }) => (
  <div>
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '1.45rem 1.0875rem',
        textAlign: 'center'
      }}
    >
    </div>
    <div style={{textAlign: 'center'}}>
      <img style={{width: 250, marginBottom: 20, marginLeft: 'auto', marginRight: 'auto'}} src="https://github.com/EvolveTechnology/evolve-brand/blob/master/logo/png/evolve_logo_primary_white.png?raw=true" alt="Evolve logo" />
    </div>
    <StyledFirebaseAuth
      uiConfig={getUiConfig(firebase)}
      firebaseAuth={firebase.auth()}
    />
  </div>
)

export default withFirebase(SignIn)