import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Sidebar from '../Sidebar'

const Container = styled.div`

  max-width: 1024px;
  margin: 0 auto;
`
const Content = styled.div`
  padding-top: 90px;
`

const Left = styled.div`
  @media (max-width: 768px) {
  }
`;
const Main = styled.div`
  @media (max-width: 768px) {
    width: 100% !important;
  }
`

const Page = styled.div`
  padding: 25px;
  h1, h2, h3, h4, h5{
    color:#f1f1f1;
  }
  p {
    color:#D3D3D3;

    &:first-child {
      color:#f1f1f1;
    }
  }

  table {
    width: 100%;
  }

  table tr {
    text-align: left;
  }

  table thead th {
    background: #f1f1f1;
    padding: 2px;
  }

  h1 {
    color: #fdbcbc;
  }

  table tbody td {
    padding: 2px;
  }

  table {
    width: 100%;
  }

  table tr {
    text-align: left;
  }

  table thead th {
    background: #f1f1f1;
    padding: 2px;
  }

  table tbody td {
    padding: 2px;
    color:#f1f1f1;
  }

  .uk-grid {
    max-width: 1024px !important;
    margin: 0 auto !important;
  }

  .uk-width-4-5 a {
    color:#333;
    border-bottom: 4px solid #f4d1cd;
    display: inline-block;
    margin: 2px;
  }

  .uk-width-4-5 a:hover {
    color:#333;
    text-decoration: none;
  }

  p:first-child {
    font-size: 20px;
    color:#f1f1f1;
  }
  ul li  {
    color: #D3D3D3;
  }
  ul li p {
    font-size: 16px !important;
  }

  .gatsby-resp-image-wrapper  {
    margin-top: 40px;
  }

  input:focus {
    outline: none;
  }

  .uk-container {
    margin: 0 auto !important;
  }
`

const Chrome = ({ children, header, logOutCallback, user }) => (
  <Container>
    {header}
    <Content className="uk-container uk-grid">
      <Left className="uk-width-1-5">
        <Sidebar logOutCallback={logOutCallback} user={user} />
      </Left>
      <Main className="uk-width-4-5">
        <Page>
        {children}
        </Page>
      </Main>
    </Content>
  </Container>
)

Chrome.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
}

export default Chrome
