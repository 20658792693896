import React from "react";
import Link from "gatsby-link";
import styled from "styled-components";

const SidebarContainer = styled.div`
  width: 250px;
  padding: 25px;

  @media (max-width: 768px) {
    padding: 0px;
  }

  header {
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 8px 0;
  }

  ul {
    padding-left: 0;
    margin: 0 0 20px 0;
    list-style: none;
  }

  li {
    padding-left: 0;
  }
`;

const Header = styled.header`
  a {
    color: #f1f1f1;
    &:hover {
      color: #f1f1f1;
    }
  }
`;

const SidebarContent = styled.div`
  @media (max-width: 768px) {
    padding: 10px 25px 25px 25px;
    position: absolute;
    top: 60px;
    background: rgb(40, 40, 40);
    width: 85%;
    z-index: 1;
    &.hidden {
      display: none;
    }
  }
`;

const StyledHref = styled.a`
  color: #c6c6c6;
  padding: 2px;
  &:hover {
    text-decoration: none;
    color: #fff;
    border-bottom: 4px solid #f4d1cd;
  }
`;

const StyledLink = styled(Link)`
  color: #c6c6c6;
  padding: 2px;
  &:hover {
    text-decoration: none;
    color: #fff;
    border-bottom: 4px solid #f4d1cd;
  }
`;

const Menu = styled.div`
  display: none;

  color: #d3d3d3;
  position: fixed;
  top: 15px;
  right: 30px;
  z-index: 30;

  .bar1,
  .bar2,
  .bar3 {
    display: block;
    width: 45px;
    height: 3px;
    background-color: #d3d3d3;
    margin: 9px 0;
    transition: 0.4s;
  }

  &.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 8px);
    transform: rotate(-45deg) translate(-9px, 8px);
  }

  &.change .bar2 {
    opacity: 0;
  }

  &.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-9px, -9px);
    transform: rotate(45deg) translate(-9px, -9px);
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showSidebar: false };
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  toggleSidebar(e) {
    this.setState(prevState => ({
      showSidebar: !prevState.showSidebar
    }));
  }

  logOut(e) {
    e.preventDefault();
    this.props.logOutCallback();
  }

  render() {
    return (
      <div>
        <SidebarContainer>
          <Menu
            onClick={this.toggleSidebar}
            className={this.state.showSidebar ? "change" : null}
          >
            <span className="bar1" />
            <span className="bar2" />
            <span className="bar3" />
          </Menu>
          <SidebarContent className={!this.state.showSidebar ? "hidden" : null}>
            <img
              src={this.props.user.photoURL}
              style={{
                width: 50,
                height: 50,
                borderRadius: 50,
                cssFloat: "left",
                marginRight: 10,
                marginTop: 10,
                marginBottom: 10
              }}
              alt="Profile pic"
            />
            <p>
              <b style={{ color: "#fff" }}>{this.props.user.displayName}</b>
              <br />
              <StyledHref
                href="https://myaccount.google.com/personal-info"
                target="_blank"
              >
                Uppdatera profil
              </StyledHref>
              <br />
              <StyledLink to="" onClick={this.logOut}>
                Logga ut
              </StyledLink>
            </p>
            <Header>
              <StyledLink to="/">Startsida</StyledLink>
            </Header>
            <ul>
              <li><StyledHref href="/employees">⭐ Medarbetare</StyledHref></li>
              <li><StyledLink to="/consultant">⭐ Konsultverksamhet</StyledLink></li>
              <li><StyledLink to="/ventures">⭐ Ventures</StyledLink></li>      
              <li><StyledLink to="/dna">⭐ Evolve DNA</StyledLink> </li>
            </ul>
            <Header>
              <StyledLink to="/employee">Anställd</StyledLink>
            </Header>
            <ul>
             <li>
                <StyledLink to="/employee/first-day">Första dagen</StyledLink>
              </li>
              <li>
                <StyledLink to="/consultant">Att vara konsult</StyledLink>
              </li>
              <li>
                <StyledLink to="/employee/work_together">
                  Jobba tillsammans-dag
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/competence/performance_coach">
                  Performance coach
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/employee/equal-dna">Equal-DNA</StyledLink>
              </li>
              <li>
                <StyledLink to="/employee/conference_rooms">
                  Konferensrum
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/employee/time_reporting">
                  Tidsrapportering
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/employee/outlay">Utlägg</StyledLink>
              </li>
              <li>
                <StyledLink to="/employee/contract">
                  Anställningsavtal
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/employee/salary">Lön</StyledLink>
              </li>
              <li>
                <StyledLink to="/employee/salary_exchange">
                  Löneväxling
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/employee/pension">Pension</StyledLink>
              </li>
    
              <li>
                <StyledLink to="/employee/gdpr">GDPR</StyledLink>
              </li>
              <li>
                <StyledLink to="/employee/holidays">Semester</StyledLink>
              </li>
              <li>
                <StyledLink to="/employee/subsistence">
                  Traktamente & resa
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/employee/insurances">Försäkringar</StyledLink>
              </li>
              <li>
                <StyledLink to="/employee/car">Förmånsbil & Bilpool</StyledLink>
              </li>
              <li>
                <StyledLink to="/employee/sick">Karensavdrag</StyledLink>
              </li>
   
            </ul>
            <Header>
              <StyledLink to="/events">Events</StyledLink>
            </Header>
            <ul>
              <li>
                <StyledLink to="/events/2021">2021</StyledLink>
              </li>
              <li>
                <StyledLink to="/events/alcohol">Alkohol</StyledLink>
              </li>
              <li>
                <StyledLink to="/events/images">Bilder</StyledLink>
              </li>
            </ul>
            <Header>
              <StyledLink to="/competence">Kompetens</StyledLink>
            </Header>
            <ul>
            <li>
                <StyledLink to="/competence/performance_coach">
                  Performance coach
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/competence/courses">
                  Kurser
                </StyledLink>
              </li>
            <li>
                <StyledLink to="/competence/travel_guide">
                  Reseguiden
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/competence/lightning_talks">
                  Lightning talks
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/competence/links">Länkar</StyledLink>
              </li>
            </ul>
            <Header>
              <StyledLink to="/recruitment">Rekrytering</StyledLink>
            </Header>
            <ul>
              <li>
                <StyledLink to="/recruitment/bonus">Bonus</StyledLink>
              </li>
              <li>
                <StyledLink to="/recruitment/process">
                  Rekryteringsprocess
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/recruitment/selling_points">
                  Försäljningsargument
                </StyledLink>
              </li>
            </ul>
            <Header>
              <StyledLink to="/tools">Verktyg</StyledLink>
            </Header>
            <ul>
              <li>
                <StyledLink to="/tools/slack">Slack</StyledLink>
              </li>
              <li>
                <StyledLink to="/tools/treks">Treks</StyledLink>
              </li>
              <li>
                <StyledLink to="/tools/skovik">Skovik</StyledLink>
              </li>
              <li>
                <StyledLink to="/tools/bitbucket">Bitbucket</StyledLink>
              </li>
              <li>
                <StyledLink to="/tools/gitlab">Gitlab</StyledLink>
              </li>
              <li>
                <StyledLink to="/tools/live">Live stream</StyledLink>
              </li>
              <li>
                <StyledLink to="/tools/vpn">VPN</StyledLink>
              </li>
            </ul>
            
            <Header>
              <StyledLink to="/purchasing">Inköp</StyledLink>
            </Header>
            <ul>
              <li>
                <StyledLink to="/purchasing/hardware">Hårdvara</StyledLink>
              </li>
              <li>
                <StyledLink to="/purchasing/software">Mjukvara</StyledLink>
              </li>
              <li>
                <StyledLink to="/purchasing/other">Övrigt</StyledLink>
              </li>
            </ul>
            <Header>
              <StyledLink to="/health">Hälsa</StyledLink>
            </Header>
            <ul>
              <li>
                <StyledLink to="/health/glasses">Glasögon</StyledLink>
              </li>
              <li>
                <StyledLink to="/health/wellnessgrant">
                  Friskvårdsbidrag
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/health/abuse">Missbruk</StyledLink>
              </li>
            </ul>
            <Header>
              <StyledLink to="/miscellaneous">Övrigt</StyledLink>
            </Header>
            <ul>
              <li>
                <StyledLink to="/miscellaneous/company_info">
                  Företagsinformation
                </StyledLink>
              </li>
              <li>
                <StyledLink to="/miscellaneous/templates">Mallar</StyledLink>
              </li>
              <li>
                <StyledLink to="/miscellaneous/logo">Logo</StyledLink>
              </li>
              <li>
                <StyledLink to="/miscellaneous/typography">Typsnitt</StyledLink>
              </li>
              <li>
                <StyledLink to="/miscellaneous/email_signature">
                  E-postsignatur
                </StyledLink>
              </li>
            </ul>
          </SidebarContent>
        </SidebarContainer>
      </div>
    );
  }
}

export default Sidebar;
