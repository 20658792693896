import React, { Component } from "react"
import PropTypes from "prop-types"
import { Index } from "elasticlunr"

import { Link } from "gatsby"

export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
      showSearch: false
    }
    this.toggleSearch = this.toggleSearch.bind(this)

  }

  toggleSearch(e) {
    this.setState(prevState => ({
      showSearch: !prevState.showSearch
    }));
  }


  render() {
    return (
      <div style={{ float: 'left'}}>
        <input
          style={{
            position: `relative`,
            backgroundColor: '#282828',
            border: 0,
            color: '#D3D3D3',
            fontSize: 20,
            paddingBottom: 20,
            paddingLeft: 10,
            paddingTop: 25,
            maxWidth: 'calc(100vw - 215px)'
          }}
          type="text"
          placeholder="Sök här.."
          value={this.state.query}
          onChange={this.search}
        />
        <ul
          style={{
            backgroundColor: `#282828`,
            marginLeft: `0`,
            listStyle: 'none',
            margin: 0,
            padding: 0
          }}
        >
          {this.state.results.map(page => (
            <li key={page.id} >
              <Link style={{color: '#D3D3D3', padding: 10, display: 'block'}} to={`https://work.evolvetechnology.se/` + page.path}>{page.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex)

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()

    console.log(this.index);
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }
}

Search.propTypes = {
  searchIndex: PropTypes.object,
}