const config = {
  apiKey: "AIzaSyAi7_V8zPA5we06SFJs6Nz95h_JaOIy4AI",
  authDomain: "evolve-intranet.firebaseapp.com",
  databaseURL: "https://evolve-intranet.firebaseio.com",
  projectId: "evolve-intranet",
  storageBucket: "evolve-intranet.appspot.com",
  messagingSenderId: "56964514138"
};
let firebaseCache;

export const getUiConfig = firebase => ({
  signInFlow: "popup",
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        hd: "evolvetechnology.se"
      }
    }
  ]
});

const getFirebase = firebase => {
  if (firebaseCache) {
    return firebaseCache;
  }

  firebase.initializeApp(config);
  firebaseCache = firebase;
  return firebase;
};

export default getFirebase;
