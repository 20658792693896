import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';
import { graphql, StaticQuery } from "gatsby"
import Search from '../Search';

const Logo = styled.div`
  width: 100%;
  max-width: 100px;
  padding: 20px 20px 20px 0;
  float: left;

  @media (max-width: 768px) {
    width: 75px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  background: #282828;
  padding: 0px 0px 0px 30px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;

const Header = () => (
  <div>
    <HeaderContainer>
      <Link to="/">
        <Logo>
          <img
            className="logo"
            alt="Evolve Logo"
            src="https://raw.githubusercontent.com/EvolveTechnology/evolve-brand/master/logo/png/evolve_logo_primary_white.png"
          />
        </Logo>
      </Link>
      <StaticQuery
          query={graphql`
              query SearchIndexQuery {
                siteSearchIndex {
                  index
                }
              }
            `}
            render={data => (
              <header>
                <Search searchIndex={data.siteSearchIndex.index} />
              </header>
            )}
        />
    </HeaderContainer>
  </div>
);

export default Header;
